package com.mothdigital.kowebapp.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.mothdigital.kowebapp.HeadlineTextStyle
import com.mothdigital.kowebapp.SubheadlineTextStyle
import com.mothdigital.kowebapp.components.layouts.PageLayout
import com.mothdigital.kowebapp.toSitePalette
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorSchemes
import io.ktor.client.HttpClient
import io.ktor.client.request.get
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle =
    CssStyle {
        base { Modifier.fillMaxWidth().gap(2.cssRem) }
        Breakpoint.MD { Modifier.margin { top(20.vh) } }
    }

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle =
    CssStyle.base {
        Modifier
            .gap(0.5.cssRem)
            .width(70.cssRem)
            .height(18.cssRem)
    }

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle =
    CssStyle.base {
        Modifier
            .backgroundColor(GridCellColorVar.value())
            .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
            .borderRadius(1.cssRem)
    }

@Composable
private fun GridCell(
    color: Color,
    row: Int,
    column: Int,
    width: Int? = null,
    height: Int? = null,
) {
    Div(
        HomeGridCellStyle
            .toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs(),
    )
}

val client = HttpClient()

@Page
@Composable
fun HomePage() {
    PageLayout("Home") {
        Row(HeroContainerStyle.toModifier()) {
            Box {
                val sitePalette = ColorMode.current.toSitePalette()

                Column(Modifier.gap(2.cssRem)) {
                    Div(HeadlineTextStyle.toAttrs()) {
                        SpanText(
                            "Use this template as your starting point for xxx ",
                            Modifier.color(
                                when (ColorMode.current) {
                                    ColorMode.LIGHT -> Colors.Black
                                    ColorMode.DARK -> Colors.White
                                },
                            ),
                        )
                        SpanText(
                            "Kobweb",
                            Modifier
                                .color(sitePalette.brand.accent)
                                // Use a shadow so this light-colored word is more visible in light mode
                                .textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray),
                        )
                    }

                    Div(SubheadlineTextStyle.toAttrs()) {
                        SpanText("You can read the ")
                        Link("/about", "About")
                        SpanText(" page for more information.")
                    }

                    val coroutineScope = rememberCoroutineScope()
                    val ctx = rememberPageContext()
                    Button(onClick = {
                        // Change this click handler with your call-to-action behavior
                        // here. Link to an order page? Open a calendar UI? Play a movie?
                        // Up to you!
                        coroutineScope.launch {
                            val x = client.get("https://jsonplaceholder.typicode.com/todos/1").status
                            println("SM_TEST $x")
                        }
                        //ctx.router.tryRoutingTo("/about")
                    }, colorScheme = ColorSchemes.Blue) {
                        Text("This could be your CTA")
                    }
                }
            }

            Div(
                HomeGridStyle
                    .toModifier()
                    .displayIfAtLeast(Breakpoint.MD)
                    .grid {
                        rows { repeat(3) { size(1.fr) } }
                        columns { repeat(5) { size(1.fr) } }
                    }.toAttrs(),
            ) {
                val sitePalette = ColorMode.current.toSitePalette()
                GridCell(sitePalette.brand.primary, 1, 1, 2, 2)
                GridCell(ColorSchemes.Monochrome._600, 1, 3)
                GridCell(ColorSchemes.Monochrome._100, 1, 4, width = 2)
                GridCell(sitePalette.brand.accent, 2, 3, width = 2)
                GridCell(ColorSchemes.Monochrome._300, 2, 5)
                GridCell(ColorSchemes.Monochrome._800, 3, 1, width = 5)
            }
        }
    }
}
