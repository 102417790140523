import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.mothdigital.kowebapp.pages.HomePage() }
        ctx.router.register("/about") { com.mothdigital.kowebapp.pages.AboutPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", com.mothdigital.kowebapp.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", com.mothdigital.kowebapp.SubheadlineTextStyle)
        ctx.theme.registerStyle("markdown",
                com.mothdigital.kowebapp.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content",
                com.mothdigital.kowebapp.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", com.mothdigital.kowebapp.components.sections.FooterStyle)
        ctx.theme.registerStyle("nav-header",
                com.mothdigital.kowebapp.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("hero-container", com.mothdigital.kowebapp.pages.HeroContainerStyle)
        ctx.theme.registerStyle("home-grid", com.mothdigital.kowebapp.pages.HomeGridStyle)
        ctx.theme.registerStyle("home-grid-cell", com.mothdigital.kowebapp.pages.HomeGridCellStyle)
        ctx.theme.registerVariant("-circle", com.mothdigital.kowebapp.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", com.mothdigital.kowebapp.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                com.mothdigital.kowebapp.components.sections.SideMenuSlideInAnim)
        com.mothdigital.kowebapp.initColorMode(ctx)
        com.mothdigital.kowebapp.initSiteStyles(ctx)
        com.mothdigital.kowebapp.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Koweb App"))
    renderComposable(rootElementId = "root") {
        com.mothdigital.kowebapp.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
